import { useModel } from '@umijs/max';
import { Avatar, Button, Flex, Popover, theme } from 'antd';
import { FC, ReactNode, useState } from 'react';
import { UserMenu } from './userMenu';
import { SunIcon } from '../icons/sun';
import { MoonIcon } from './../icons/moon';

interface AvatarDropdownProps {
  menu?: boolean;
  avatar?: ReactNode;
  name?: ReactNode;
  children?: ReactNode;
}

export const AvatarDropdown: FC<AvatarDropdownProps> = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const { user } = initialState || {};
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  if (!user || !user.firstname || !user.lastname) return null;

  const label = user.firstname.charAt(0).toUpperCase() + user.lastname?.charAt(0).toUpperCase();
  const userTheme = initialState?.theme === 'light' ? 'realDark' : 'light';
  return (
    <Flex gap={'small'} align={'center'}>
      {/* <Button type="text" icon={<MessageOutlined />} /> */}
      <Button
        type="text"
        icon={userTheme === 'light' ? <SunIcon /> : <MoonIcon />}
        onClick={() => {
          if (initialState) {
            setInitialState({
              ...initialState,
              theme: userTheme,
            });
            localStorage.setItem('theme', userTheme);
          }
        }}
      />
      <Popover
        trigger={['click']}
        content={<UserMenu collapsed={false} afterClick={() => setOpen(false)} />}
        open={open}
        onOpenChange={(open) => setOpen(open)}
      >
        {/* <Badge dot> */}
        <Avatar src={user.picture} style={{ background: token.colorPrimary, objectFit: 'cover' }}>
          {user.picture ? '' : label}
        </Avatar>
        {/* </Badge> */}
      </Popover>
    </Flex>
  );
};
