import { gql } from '@apollo/client';
import { VBBaseClass } from './generic';
import {
  AdminCreateAgentInput,
  AdminResendPasswordInput,
  AdminUpdateAgentInput,
  Agent,
} from '@/generated/graphql';
import { mutate } from '.';

const DEFAULT_FIELDS = `
  email
  firstname
  lastname
  phone
  salutation
  id
  additional_info
  role
  client_id
  picture
`;

export class VBUser {
  private static baseClass = new VBBaseClass<Agent>('Agent', DEFAULT_FIELDS);

  static createOne(data: AdminCreateAgentInput) {
    const query = gql`
      mutation AdminCreateAgent($agentData: AdminCreateAgentInput!) {
        user: adminCreateAgent(agent_data: $agentData) {
          email
          firstname
          lastname
          salutation
          id
        }
      }
    `;
    return mutate<{ user: Agent }>({
      mutation: query,
      variables: { agentData: data },
    });
  }

  static updateOne(data: AdminUpdateAgentInput) {
    const query = gql`
      mutation AdminUpdateAgent($agentData: AdminUpdateAgentInput!) {
        user: adminUpdateAgent(agent_data: $agentData) {
          email
          firstname
          lastname
          salutation
          id
        }
      }
    `;
    return mutate<{ user: Agent }>({
      mutation: query,
      variables: { agentData: data },
    });
  }

  static deleteOne(old_user_id: string, new_user_id: string, user_pool_id: string) {
    const query = gql`
      mutation AdminDeleteAgent($input: AdminDeleteAgentInput!) {
        adminDeleteAgent(input: $input) {
          success
        }
      }
    `;
    return mutate<{ adminDeleteAgent: { success: boolean } }>({
      mutation: query,
      variables: {
        input: {
          dest_user_pool_id: user_pool_id,
          new_user_id,
          old_user_id,
        },
      },
    });
  }

  static selectOne(cognito_user_id: string) {
    return this.baseClass.findFirst(cognito_user_id);
  }

  static selectMany(client_id: string) {
    return this.baseClass.selectMany(
      [
        { field: 'firstname', type: 'sortOrder' },
        { field: 'lastname', type: 'sortOrder' },
      ],
      { client_id, cognito_user_id: '!null', deleted: null },
    );
  }

  static sendPassword(input: AdminResendPasswordInput) {
    const query = gql`
      mutation AdminResendPassword($input: AdminResendPasswordInput!) {
        adminResendPassword(input: $input) {
          success
        }
      }
    `;
    return mutate<{ adminResendPassword: { success: boolean } }>({
      mutation: query,
      variables: { input },
    });
  }
}
